import React from "react";
import style from "./Whoweare.module.css";
import leftrec from "../../assets/leftrec.png";
import rightrec from "../../assets/rightrec.png";
import leftarrow from "../../assets/leftarrow.png";
import rightarrow from "../../assets/rightarrow.png";
import { useTranslation } from "react-i18next";
const Whoweare = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center mb-16">
      <div className=" xsm:max-lg:block xsm:max-lg:text-center pt-40  xsm:max-md:pt-14 mb-10 flex justify-between items-center xsm:max-md:w-full xsm:max-md:px-5 w-11/12 m-auto">
        <img src={leftarrow} className="lg:block hidden" />
        <h2 className="text-8xl xsm:max-lg:text-5xl xsm:max-lg:font-bold">
          {t("who")}
        </h2>
        <img src={rightarrow} className="lg:block hidden" />
      </div>
      <div className={`xsm:max-lg:p-5 p-10 ${style.background} `}>
        <p className="xsm:max-lg:w-full xsm:max-lg:text-2xl xsm:max-lg:leading-[40px] text-[#ffff] text-center text-4xl font-normal m-auto leading-[100px] w-9/12">
          {t("who_des")}
        </p>
      </div>
    </div>
  );
};
export default Whoweare;
