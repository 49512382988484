import React, { useState } from "react";
import background from "../../assets/landingimg.jpg";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase/Config";
import { collection, getDocs, query, where } from "firebase/firestore";
const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const submit = async (e) => {
    e.preventDefault();
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        var usertype = "";
        const q = query(
          collection(db, "users"),
          where("uid", "==", userCredential.user.uid)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          usertype = doc.data().rule;
        });
        return usertype;
      })
      .then((usertype) => {
        if (usertype === "admin") {
          return navigate("/dashboard");
        } else {
          return navigate("/");
        }
      })
      .catch((error) => {
        setError(true);
      });
  };
  return (
    <div
      className="flex h-screen w-full items-center justify-center bg-gray-900 bg-cover bg-no-repeat text-white"
      style={{ backgroundImage: `url('${background}')` }}>
      <div className="rounded-xl bg-gray-800 bg-opacity-50 px-16 xsm:max-lg:px-2 py-10 shadow-lg backdrop-blur-md max-sm:px-8 w-4/12 xsm:max-lg:w-11/12">
        <div className="text-white">
          <div className="mb-8 flex flex-col items-center">
            <img src={logo} width="150" alt="" srcset="" />
            <span className="text-[#ffffff] font-bold text-xl">
              Enter Login Details
            </span>
          </div>
          <form onSubmit={submit}>
            <div className="mb-4 text-lg text-center">
              <input
                onChange={(e) => setEmail(e.target.value)}
                className=" rounded-3xl xsm:max-lg:w-11/12 w-7/12 border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
                type="text"
                name="name"
                placeholder="id@email.com"
              />
            </div>

            <div className="mb-4 text-lg text-center">
              <input
                onChange={(e) => setPassword(e.target.value)}
                className="rounded-3xl xsm:max-lg:w-11/12 w-7/12 border-none bg-yellow-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
                type="Password"
                name="name"
                placeholder="*********"
              />
            </div>
            <div className="mt-8 flex justify-center text-lg text-black ">
              <button
                type="submit"
                className="bg-[#ffffff] rounded-3xl text-[#3B2800] px-10 py-2  shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
