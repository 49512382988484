// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage} from "firebase/storage"
import {getAuth} from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging/sw";
const firebaseConfig = {
  apiKey: "AIzaSyB7i2gbbk2f9Tk1mIwWgUsuNJTG0zFrBbE",
  authDomain: "cravio-68251.firebaseapp.com",
  projectId: "cravio-68251",
  storageBucket: "cravio-68251.appspot.com",
  messagingSenderId: "15770853703",
  appId: "1:15770853703:web:1f67f43983c1db454c2c62",
  measurementId: "G-VQV0YHB6RC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore(app);
// export const messaging = getMessaging(firebaseConfig);