import { defaults } from "autoprefixer";
import React from "react";
import Whoweare from "../component/aboutus/Whoweare";
import Sectiontwo from "../component/aboutus/Sectiontwo";
import Whychooseus from "../component/aboutus/Whychooseus";
import Processfollow from "../component/aboutus/Processfollow";
import Ourteam from "../component/aboutus/Ourteam";
const Aboutus=()=>{
    return(
        <>
            <Whoweare/>
            {/* <Sectiontwo/> */}
            <Whychooseus/>
            {/* <Processfollow/>
            <Ourteam/> */}
        </>
    )
}
export default Aboutus 