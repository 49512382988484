import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/footer/Footer";
const Rootrouter=()=>{
    const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);
  
    // useEffect(() => {
    //   // Check if Geolocation is supported by the browser
    //   if (navigator.geolocation) {
    //     // Get the user's current location
    //     navigator.geolocation.getCurrentPosition(
    //       (position) => {
    //         // Extract latitude and longitude from the position object
    //         const { latitude, longitude } = position.coords;
  
    //         // Set the location state
    //         setLocation({ latitude, longitude });
    //       },
    //       (error) => {
    //         // Handle errors, e.g., user denies location access
    //         setError(error.message);
    //       }
    //     );
    //   } else {
    //     setError('Geolocation is not supported by your browser');
    //   }
    // }, []); // Run this effect only once when the component mounts
    // console.log(location)
    return(
        <div className="relative">
            <Navbar/>
            <Outlet/>
            <Footer/>
        </div>
    )
}
export default Rootrouter;