import React  from "react";
const Header=()=>{
    return(
        <div className="text-center mb-10">
            <div className="xsm:max-lg:block xsm:max-lg:text-center pt-40 mb-10 ">
                <h2 className="text-8xl xsm:max-lg:text-5xl xsm:max-lg:font-bold">Our <span className="text-[#653C1F]">Work</span></h2>
            </div>
        </div>
    )
}
export default Header