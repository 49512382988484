import React, { useContext, useEffect } from "react";
import logo from "../../assets/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoLanguage } from "react-icons/io5";
import i18next from "i18next";
import { Authcontext } from "../context/Authcontext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/Config";
// import { t } from "i18next";
const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  const auths = useContext(Authcontext);
  const changeLanguageHandler = (newLang) => {
    localStorage.setItem('lang', newLang);
    document.querySelector('html').lang = newLang;
    document.documentElement.setAttribute('dir', newLang === 'ar' ? 'rtl' : 'ltr');
    i18next.changeLanguage(newLang);
  };
  useEffect(() => {
    const storedLang = localStorage.getItem('lang') || 'en';
    changeLanguageHandler(storedLang);
  }, []);
  return (
    <div className="navbar min-h-[8rem] items-start absolute z-20 lg:overflow-hidden">
      <div className="flex-1">
        <a className="btn btn-ghost normal-case text-xl">
          <img width="100px" height="100px" src={logo} alt="logo" />
        </a>
      </div>
      <div className="flex-none .nav hidden lg:flex">
        <ul className="menu menu-horizontal pr-[200px] text-2xl text-[#ffff]">
          <li className="px-16 text-[#ffff]">
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive
                  ? "text-[#ffff] hover:bg-transparent focus:bg-transparent active:bg-transparent"
                  : "hover:bg-transparent focus:bg-transparent active-bg-transparent"
              }>
              {t("home")}
            </NavLink>
          </li>
          {/* <li className="px-16 text-[#865C00]"><NavLink to="chat" className={({isActive})=>isActive?"text-[#3B2800] hover:bg-transparent focus:bg-transparent active:bg-transparent":"hover:bg-transparent focus:bg-transparent active-bg-transparent"}>Chat</NavLink></li> */}
          <li className="px-16 text-[#ffff]">
            <NavLink
              to="/aboutus"
              className={({ isActive }) =>
                isActive
                  ? "text-[#ffff] hover:bg-transparent focus:bg-transparent active:bg-transparent"
                  : "hover:bg-transparent focus:bg-transparent active-bg-transparent"
              }>
              {t("about_us")}
            </NavLink>
          </li>
          <li className="px-16 text-[#ffff]">
            <NavLink
              to="/gallery"
              className={({ isActive }) =>
                isActive
                  ? "text-[#ffff] hover:bg-transparent focus:bg-transparent active:bg-transparent"
                  : "hover:bg-transparent focus:bg-transparent active-bg-transparent"
              }>
              {t("gallery")}
            </NavLink>
          </li>
          {auths?.currentuser && (
            <li className="px-16 text-[#ffff]">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive
                    ? "text-[#ffff] hover:bg-transparent focus:bg-transparent active:bg-transparent"
                    : "hover:bg-transparent focus:bg-transparent active-bg-transparent"
                }>
                {t("dashboard")}
              </NavLink>
            </li>
          )}
          <li className="px-16 text-[#ffff]">
            <p
              //   to="/dashboard"
              onClick={() => {
                const lang = localStorage.getItem("lang") || "en";
                const newLang = lang === "ar" ? "en" : "ar";
                changeLanguageHandler(newLang);
              }}
              className={({ isActive }) =>
                isActive
                  ? "text-[#ffff] hover:bg-transparent focus:bg-transparent active:bg-transparent"
                  : "hover:bg-transparent focus:bg-transparent active-bg-transparent"
              }>
              <IoLanguage color="white" size={30} />
            </p>
          </li>
          <div className=" justify-center">
            {auths?.usertype === null && (
              <button
                onClick={() => navigate("/login")}
                className="btn bg-[#3B2800] border-0 text-2xl h-14 px-10 text-[#ffffff] hover:border-2 hover:bg-[transparent] hover:border-white md:max-lg:px-7 xsm:max-md:px-1 xsm:max-md:text-sm">
                {t("login")}
              </button>
            )}

            {auths?.usertype !== null && (
              <button
                onClick={() => signOut(auth).then(() => navigate("/"))}
                className="btn btn-outline border-2 border-white px-10 text-2xl h-14 text-[#ffffff] hover:border-0 hover:bg-[#3B2800] md:max-lg:px-7 xsm:max-md:px-1 mr-10 xsm:max-md:text-sm xsm:max-md:mr-3">
                {t("logout")}
              </button>
            )}
          </div>
          {/* <li className="px-16 text-[#865C00]"><NavLink to="portfolio" className={({isActive})=>isActive?"text-[#3B2800] hover:bg-transparent focus:bg-transparent active:bg-transparent":"hover:bg-transparent focus:bg-transparent active-bg-transparent"}>Portfolio</NavLink></li> */}
          {/* <li className="px-16 text-[#865C00]"><NavLink to="services" className={({isActive})=>isActive?"text-[#3B2800] hover:bg-transparent focus:bg-transparent active:bg-transparent":"hover:bg-transparent focus:bg-transparent active-bg-transparent"}>Services</NavLink></li> */}
        </ul>
      </div>
      <div className="navbar-end w-2/12 xsm:max-md:w-5/12 mt-10 ">
        <div className="dropdown text-[white]">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className={`menu menu-lg dropdown-content mt-3 z-[1000] p-5 shadow text-right rounded-box w-56 bg-white text-[white] ${
              lang === "ar" ? "left-0" : "right-0"
            }  bg-[#865C00]`}>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            {/* <li><NavLink to="chat">Chat</NavLink></li> */}
            <li>
              <NavLink to="/aboutus">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/gallery">Gallery</NavLink>
            </li>
            {auths?.currentuser && (
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#3B2800] hover:bg-transparent focus:bg-transparent active:bg-transparent"
                      : "hover:bg-transparent focus:bg-transparent active-bg-transparent"
                  }>
                  {t("dashboard")}
                </NavLink>
              </li>
            )}
            <li>
              {auths?.usertype === null && (
                <button
                  onClick={() => navigate("login")}
                  className="btn bg-[#3B2800] border-0 text-2xl h-14 px-10 text-[#ffffff] hover:border-2 hover:bg-[transparent] hover:border-white md:max-lg:px-7 xsm:max-md:px-1 xsm:max-md:text-sm">
                  تسجيل الدخول
                </button>
              )}

              {auths?.usertype !== null && (
                <button
                  onClick={() => signOut(auth).then(() => navigate("/"))}
                  className="btn btn-outline border-2 border-white px-10 text-2xl h-14 text-[#ffffff] hover:border-0 hover:bg-[#3B2800] md:max-lg:px-7 xsm:max-md:px-1 mr-10 xsm:max-md:text-sm xsm:max-md:mr-3">
                  تسجيل الخروج
                </button>
              )}
            </li>
            {/* <li><NavLink to="portfolio">Portfolio</NavLink></li>
                        <li><NavLink to="Services">services</NavLink></li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
