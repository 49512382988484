import React, { useEffect, useState } from "react";
import { GrEdit } from "react-icons/gr";
import rightLine from "../../assets/rightLine.png"
import leftLine from "../../assets/leftLine.png"
import { MdDelete } from "react-icons/md";
import {useNavigate} from "react-router-dom"
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config";
const Sections=()=>{
    const navigate = useNavigate();
    const [sections,setSections]=useState([])
    const getSectionsHandler=async()=>{
        const querySnapshot = await getDocs(collection(db, "sections"));
        const demo=[]
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            demo.push(doc.data())
        });
        setSections(demo)
    }
    useEffect(()=>{
        getSectionsHandler()
    },[])
    const deleteSection=async (id)=>{
        await deleteDoc(doc(db, "sections", id));
        getSectionsHandler()
    }
    return(
        <div className="min-h-screen relative">
            <div className="absolute bottom-0 left-0">
                <img src={leftLine}/>
            </div>
            <div className="text-right  px-5">
                <button onClick={()=>navigate("/dashboard/sections/sectioninputs/Add")} className="btn bg-[#653C1F] text-[#ffff] hover:bg-[#885633]">Add Section</button>
            </div>
            <div className="divider mb-5"></div> 
            <div className="flex justify-center ">
                <div className="overflow-x-auto z-10 my-14 shadow-lg w-6/12 rounded-md relative">
                    <table className="table">
                        {/* head */}
                        <thead>
                        <tr className=" text-[#653C1F] font-black text-2xl text-center">
                            <th>Section Name</th>
                            <th>Photo</th>
                            <th colspan="2">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="text-[#00000080] text-2xl font-black text-center">
                            {sections?.map((sec)=>{
                                return(
                                    <tr>
                                        <td className="border-x border-[white]">{sec?.name}</td>
                                        <td className="border-x border-[white]">
                                            <div tabIndex={0} role="button" className=" w-24 h-auto btn btn-ghost btn-circle avatar">
                                                <div className=" rounded-full">
                                                    <img alt="Tailwind CSS Navbar component" src={sec?.photo} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="border-x border-[white]">
                                            <GrEdit onClick={()=>navigate(`/dashboard/sections/sectioninputs/${sec?.id}`)} className="m-auto  cursor-pointer" />
                                        </td>
                                        <td className="border-x border-[white]">
                                            <MdDelete onClick={()=>deleteSection(sec?.id)} className="m-auto text-[#C10F0F] cursor-pointer" />
                                        </td>
                                    </tr>
                                )
                            })}
                        {/* row 1 */}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="absolute bottom-0 right-0">
                <img src={rightLine}/>
            </div>
        </div>
    )
}
export default Sections