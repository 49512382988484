import React, { useContext, useEffect } from "react";
import logo from "../../assets/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { AiTwotoneSetting } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { HiBriefcase, HiMail, HiUsers } from "react-icons/hi";
import { BiSolidLogOut } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/Config";
import { Authcontext } from "../context/Authcontext";
import i18next from "i18next";
const Navside = () => {
  const navigate = useNavigate();
  const auths = useContext(Authcontext);
  // const dispatch = useDispatch();
  // const Logouthandler=()=>{
  //     dispatch(removeActiveUserHandler())
  // }
  const changeLanguageHandler = (newLang) => {
    localStorage.setItem("lang", newLang);
    document.querySelector("html").lang = newLang;
    document.documentElement.setAttribute(
      "dir",
      newLang === "ar" ? "rtl" : "ltr"
    );
    i18next.changeLanguage(newLang);
  };
  useEffect(() => {
    const storedLang = "en";
    changeLanguageHandler(storedLang);
  }, []);
  return (
    <>
      <div className="drawer w-24">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <label
            htmlFor="my-drawer"
            className="btn w-14 bg-[#653C1F] hover:bg-[#885633] text-[#ffff]  swap swap-rotate">
            <input type="checkbox" />
            <svg
              className="swap-off fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 512 512">
              <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
            </svg>
            <svg
              className="swap-on fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 512 512">
              <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
            </svg>
          </label>
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content relative">
            <img
              src={logo}
              onClick={() => navigate("/")}
              alt="logo"
              className="mb-10 w-5/12 h-5/12 mx-auto cursor-pointer"
            />
            <NavLink
              to="sections"
              className={({ isActive }) =>
                isActive
                  ? "font-black  hover:bg-[#653C1F] hover:text-[#ffff] bg-[#653C1F] text-[#ffff] p-4 mb-4 rounded-xl text-xl flex items-center"
                  : "font-black   bg-transparent hover:bg-[#653C1F] hover:text-[#ffff] flex items-center text-[#000000] p-4 mb-4 rounded-xl text-2xl"
              }>
              <FaCalendarAlt size={20} className="mr-3" />
              Sections
            </NavLink>
            <NavLink
              to="partSection"
              className={({ isActive }) =>
                isActive
                  ? "font-black  hover:bg-[#653C1F] hover:text-[#ffff] bg-[#653C1F] text-[#ffff] p-4 mb-4 rounded-xl text-xl flex items-center"
                  : "font-black   bg-transparent hover:bg-[#653C1F] hover:text-[#ffff] flex items-center text-[#000000] p-4 mb-4 rounded-xl text-2xl"
              }>
              <FaCalendarAlt size={20} className="mr-3" />
              Part Section
            </NavLink>
            <NavLink
              to="projects"
              className={({ isActive }) =>
                isActive
                  ? "font-black  hover:bg-[#653C1F] hover:text-[#ffff] bg-[#653C1F] text-[#ffff] p-4 mb-4 rounded-xl text-xl flex items-center"
                  : "font-black bg-transparent  hover:bg-[#653C1F] hover:text-[#ffff] flex items-center text-[#000000] p-4 mb-4 rounded-xl text-2xl"
              }>
              <FaCalendarAlt size={20} className="mr-3" />
              Projects Photo
            </NavLink>
            <NavLink
              to="reviews"
              className={({ isActive }) =>
                isActive
                  ? "font-black  hover:bg-[#653C1F] hover:text-[#ffff] bg-[#653C1F] text-[#ffff] p-4 mb-4 rounded-xl text-xl flex items-center"
                  : "font-black bg-transparent  hover:bg-[#653C1F] hover:text-[#ffff] flex items-center text-[#000000] p-4 mb-4 rounded-xl text-2xl"
              }>
              <FaCalendarAlt size={20} className="mr-3" />
              Reviews
            </NavLink>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "font-black  hover:bg-[#653C1F] hover:text-[#ffff] bg-[#653C1F] text-[#ffff] p-4 mb-4 rounded-xl text-xl flex items-center"
                  : "font-black bg-transparent  hover:bg-[#653C1F] hover:text-[#ffff] flex items-center text-[#000000] p-4 mb-4 rounded-xl text-2xl"
              }>
              <FaCalendarAlt size={20} className="mr-3" />
              Landing
            </NavLink>
          </ul>
        </div>
      </div>
      <div className="navbar h-5/6">
        <div className="flex-1">
          <a className=" font-black  text-4xl text-[#00000026]">
            Hello Admin !
          </a>
        </div>
        <div className="flex-none gap-2">
          <div className="form-control">
            <p
              onClick={() => signOut(auth).then(() => navigate("/"))}
              className={({ isActive }) =>
                isActive
                  ? "hover:bg-[#653C1F] hover:text-[#ffff] bg-[#40ABF3] text-[#ffff] p-2  rounded-xl text-lg cursor-pointer "
                  : "cursor-pointer hover:bg-[#653C1F] font-black  hover:text-[#ffff] flex items-center text-[#000000] p-2 rounded-xl text-lg  "
              }>
              <FiLogOut size={40} />
            </p>
          </div>
          <div
            tabIndex={0}
            role="button"
            className=" w-24 h-auto btn btn-ghost btn-circle avatar">
            <div className=" rounded-full">
              <img alt="Profile" src={auths?.currentuser?.photourl} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Navside;
