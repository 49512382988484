import React from "react";
import style from "./Whycravio.module.css";
import icon from "../../assets/whyicon.png";
import { useTranslation } from "react-i18next";
const Whycravio = () => {
  const { t } = useTranslation();
        // <div className="xsm:max-lg:w-full xsm:max-md:block text-center  flex justify-center items-center text-[#ffff] w-6/12 m-auto">
        //   {/* <img className="xsm:max-md:mx-auto" src={icon} alt="icon" /> */}
        //   <p className="text-5xl xsm:max-lg:text-3xl">
        //     {t("cravio-help")}
        //     {/* Our company will help you
        //                 to solve the following tasks */}
        //   </p>
        // </div>
  return (
    <div className="text-center">
      <h2 className="text-5xl font-black m-5 xsm:max-md:font-bold xsm:max-md:text-4xl">
        {/* Why <span className="text-[#653C1F]">Craivo</span> Company  */}
        {/* {t("why-cravio")} */}
        {t("cravio-help")}
      </h2>
      <div className={`text-center p-10 rounded-t-3xl ${style.background}`}>
        <ul className="mt-24">
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why1")}
              {/* Develop a layout tat meets the requirements of the Snip and
              coordinate it */}
            </p>
          </li>
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why2")}
              {/* Save your nerves and time when communicating with Contractors or
              choosing martials */}
            </p>
          </li>
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why3")}
              {/* Prevent unnecessary costs associated with alteration */}
            </p>
          </li>
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center  text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why4")}
              {/* Generate an accurate estimate for furniture,matrials,and
              construction work{" "} */}
            </p>
          </li>
          {/* <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p>
              {t("why5")}
            </p>
          </li> */}
        </ul>
        <div className="xsm:max-md:block flex justify-center items-center text-[#ffff] mt-16 text-left">
          <div className="xsm:max-md:w-full xsm:max-md:text-center flex flex-col w-64 text-center justify-center p-10">
            <h2 className="text-5xl">53</h2>
            <p className="text-2xl">
              {/* Experienced designers */}
              {t("special_need")}
            </p>
          </div>
          <div className="xsm:max-md:w-full xsm:max-md:text-center flex flex-col w-64 text-center justify-center p-10">
            <h2 className="text-5xl">77</h2>
            <p className="text-2xl">
              {/* Ready-modeProjects */}
              {t("ready_mode")}
            </p>
          </div>
          <div className="xsm:max-md:w-full xsm:max-md:text-center flex flex-col w-64 text-center justify-center p-10">
            <h2 className="text-5xl">80</h2>
            <p className="text-2xl">
              {/* Clients Reviews */}
              {t("client_grade")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Whycravio;
