import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import style from "./Ourclients.module.css";
import { BiLike, BiDislike } from "react-icons/bi";
import demophoto from "../../assets/portfoilo2.png";
import { LuQuote } from "react-icons/lu";
import { EffectCoverflow, Navigation } from "swiper/modules";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config";
const Ourclients = () => {
  const [data, setData] = useState([]);
  let myMediaQuery = window.matchMedia("(max-width: 1024px)");
  const { t } = useTranslation();
  let num = 3;
  function widthChangeCallback(myMediaQuery) {
    if (myMediaQuery.matches) {
      num = 1;
    } else {
      num = 3;
    }
    return num;
  }
  const getProjectsHandler = async () => {
    const querySnapshot = await getDocs(collection(db, "reviews"));
    const demo = [];
    querySnapshot.forEach((doc) => {
      demo.push(doc.data());
    });
    setData(demo);
  };
  useEffect(() => {
    getProjectsHandler();
  }, []);
  console.log(data);
  return (
    <div className="text-center">
      <h2 className="text-5xl m-14 xsm:max-md:font-bold xsm:max-md:text-4xl">
        {/* Our Clients <span className="text-[#653C1F]">Reviews</span> */}
        {t("client_grade")}
      </h2>
      <div className={`px-16 py-28 text-center relative ${style.backimg}`}>
        <div style={{ zIndex: "10" }} className="prev absolute top-2/4 left-0">
          <IoIosArrowBack
            style={{ cursor: "pointer" }}
            size={50}
            color="#ffff"
          />
        </div>
        <div style={{ zIndex: "10" }} className="next absolute top-2/4 right-0">
          <IoIosArrowForward
            style={{ cursor: "pointer" }}
            size={50}
            color="#ffff"
          />
        </div>
        <Swiper
          spaceBetween={0}
          slidesPerView={widthChangeCallback(myMediaQuery)}
          loop={true}
          modules={[EffectCoverflow, Navigation]}
          navigation={{ nextEl: ".next", prevEl: ".prev" }}>
          {data?.map((dat) => {
            return (
              <SwiperSlide className="px-14 xsm:max-lg:px-0">
                <div className="xsm:max-lg:block xsm:max-lg:p-5  relative w-full bg-[#000000] pl-24 pr-1 py-5 text-[#ffff] rounded-2xl flex flex-col">
                  <div className=" avatar lg:absolute lg:left-[-10%] top-[25%]">
                    <div className="w-24 rounded-full">
                      <img src={dat?.photo} />
                    </div>
                  </div>
                  <h2 className="text-[#A87300] text-2xl font-bold mb-5">
                    {dat?.name}
                  </h2>
                  <p className="w-11/12 text-left m-auto text-xl relative py-2">
                    {dat?.comment}
                    {/* <LuQuote
                      size={25}
                      color="#A87300"
                      className="xsm:max-lg:hidden absolute top-[-50%]"
                    /> */}
                  </p>
                  <div className="flex justify-center mt-4">
                    <BiLike color="#A87300" className="mr-2" size={30} />
                    {/* <BiDislike size={30} /> */}
                  </div>
                  <div className="xsm:max-lg:hidden avatar lg:absolute lg:left-[-10%] top-[25%]">
                    <div className="w-24 rounded-full">
                      <img src={dat?.photo} />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          {/* <SwiperSlide className="px-14 xsm:max-lg:px-0">
            <div className="xsm:max-lg:block xsm:max-lg:p-5  relative w-full bg-[#000000] pl-24 pr-1 py-5 text-[#ffff] rounded-2xl flex flex-col">
              <div className=" avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
              <h2 className="text-[#A87300] text-2xl font-bold mb-5">
                Gamal mohamed
              </h2>
              <p className="w-11/12 text-left m-auto text-xl relative">
                Very impressive Companyi cant imagine how good you are{" "}
                <LuQuote
                  size={25}
                  color="#A87300"
                  className="xsm:max-lg:hidden absolute top-[-50%]"
                />
              </p>
              <div className="flex justify-center mt-4">
                <BiLike color="#A87300" className="mr-2" size={30} />
                <BiDislike size={30} />
              </div>
              <div className="xsm:max-lg:hidden avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="px-14 xsm:max-lg:px-0">
            <div className="xsm:max-lg:block xsm:max-lg:p-5  relative w-full bg-[#000000] pl-24 pr-1 py-5 text-[#ffff] rounded-2xl flex flex-col">
              <div className=" avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
              <h2 className="text-[#A87300] text-2xl font-bold mb-5">
                Gamal mohamed
              </h2>
              <p className="w-11/12 text-left m-auto text-xl relative">
                Very impressive Companyi cant imagine how good you are{" "}
                <LuQuote
                  size={25}
                  color="#A87300"
                  className="xsm:max-lg:hidden absolute top-[-50%]"
                />
              </p>
              <div className="flex justify-center mt-4">
                <BiLike color="#A87300" className="mr-2" size={30} />
                <BiDislike size={30} />
              </div>
              <div className="xsm:max-lg:hidden avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="px-14 xsm:max-lg:px-0">
            <div className="xsm:max-lg:block xsm:max-lg:p-5  relative w-full bg-[#000000] pl-24 pr-1 py-5 text-[#ffff] rounded-2xl flex flex-col">
              <div className=" avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
              <h2 className="text-[#A87300] text-2xl font-bold mb-5">
                Gamal mohamed
              </h2>
              <p className="w-11/12 text-left m-auto text-xl relative">
                Very impressive Companyi cant imagine how good you are{" "}
                <LuQuote
                  size={25}
                  color="#A87300"
                  className="xsm:max-lg:hidden absolute top-[-50%]"
                />
              </p>
              <div className="flex justify-center mt-4">
                <BiLike color="#A87300" className="mr-2" size={30} />
                <BiDislike size={30} />
              </div>
              <div className="xsm:max-lg:hidden avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="px-14 xsm:max-lg:px-0">
            <div className="xsm:max-lg:block xsm:max-lg:p-5  relative w-full bg-[#000000] pl-24 pr-1 py-5 text-[#ffff] rounded-2xl flex flex-col">
              <div className=" avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
              <h2 className="text-[#A87300] text-2xl font-bold mb-5">
                Gamal mohamed
              </h2>
              <p className="w-11/12 text-left m-auto text-xl relative">
                Very impressive Companyi cant imagine how good you are{" "}
                <LuQuote
                  size={25}
                  color="#A87300"
                  className="xsm:max-lg:hidden absolute top-[-50%]"
                />
              </p>
              <div className="flex justify-center mt-4">
                <BiLike color="#A87300" className="mr-2" size={30} />
                <BiDislike size={30} />
              </div>
              <div className="xsm:max-lg:hidden avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="px-14 xsm:max-lg:px-0">
            <div className="xsm:max-lg:block xsm:max-lg:p-5  relative w-full bg-[#000000] pl-24 pr-1 py-5 text-[#ffff] rounded-2xl flex flex-col">
              <div className=" avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
              <h2 className="text-[#A87300] text-2xl font-bold mb-5">
                Gamal mohamed
              </h2>
              <p className="w-11/12 text-left m-auto text-xl relative">
                Very impressive Companyi cant imagine how good you are{" "}
                <LuQuote
                  size={25}
                  color="#A87300"
                  className="xsm:max-lg:hidden absolute top-[-50%]"
                />
              </p>
              <div className="flex justify-center mt-4">
                <BiLike color="#A87300" className="mr-2" size={30} />
                <BiDislike size={30} />
              </div>
              <div className="xsm:max-lg:hidden avatar lg:absolute lg:left-[-10%] top-[25%]">
                <div className="w-24 rounded-full">
                  <img src={demophoto} />
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};
export default Ourclients;
