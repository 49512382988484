import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next'; // Correct import
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ar from "./locales/ar/translations.json";
import en from "./locales/en/translations.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
  });

export default i18n;
