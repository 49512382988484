import React from "react";
const ProjectCard=(props)=>{
    return(
        <div className="card w-[550px] xsm:max-md:w-56 xsm:max-md:m-auto  cursor-pointer">
            <figure className="shadow-xl">
                <img src={props?.project?.photo}  alt="Shoes" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="card-title text-2xl font-extrabold">{props?.project?.name}</h2>
                <p className="w-11/12 text-[#000000] text-xl font-bold">{props?.project?.definition}</p>
            </div>
        </div>
    )
}
export default ProjectCard