import React from "react";
import aboutusvector from "../../assets/aboutusvector.png";
import style from "./Aboutus.module.css";
import button from "../../assets/button.png";
import { useTranslation } from "react-i18next";
const Aboutus = () => {
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();
  return (
    <div id="about" className=" relative">
      <div
        className={` absolute ${
          lang === "ar" ? "left-28" : "right-28"
        } overflow-hidden rounded-l-3xl z-0 lg:block hidden`}>
        <img src={aboutusvector} alt="background" />
      </div>
      <div className="flex items-center xsm:max-lg:block">
        <div
          className={`relative text-right mt-32 xsm:max-lg:m-0   ${
            lang === "ar" ? "rounded-l-3xl" : "rounded-r-3xl left-[-10%]"
          } ${style.backimg}`}>
          <h2
            className={`absolute ${
              lang === "ar" ? "left-[-90%] xsm:max-lg:left-0" : "right-[-10%]"
            } top-60 xsm:max-lg:top-14 text-8xl xsm:max-lg:text-7xl z-10 text-[#ffff] xsm:max-lg:text-[black] ${
              style.after
            }`}>
            {/* About <span className="text-[#653C1F]">us</span> */}
            {t("about_us")}
          </h2>
        </div>
        <div
          className={`${
            lang === "ar" ? "xsm:max-lg:mr-0 mr-10" : ""
          } relative xsm:max-lg:p-5 w-8/12 mt-80 xsm:max-lg:mt-24 xsm:max-lg:text-center xsm:max-lg:w-full`}>
          <p className="text-4xl !font-black xsm:max-lg:text-2xl xsm:max-lg:w-full w-10/12 leading-loose xsm:max-lg:mx-auto  mb-24 xsm:max-lg:mb-16">
            {t("about_describtion")}
            {/* Hi we are <span className="text-[#653C1F]">Craivo</span> Company with a lot of experience.
                        we have been engaged in interior design , Electrical, mechanical and civil engineering more than 
                        <span className="text-[#653C1F]">10 years</span> - we always work for the best result so the customers are satisfied as possible.  */}
          </p>
          {/* <div className="flex items-center justify-center w-8/12 xsm:max-lg:mx-auto">
                        <img className="cursor-pointer" src={button} alt="button"/>
                        <p className=" text-xl font-bold">{t("learn_more")}</p>
                    </div> */}
        </div>
      </div>
    </div>
  );
};
export default Aboutus;
