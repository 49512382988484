import React, { useContext, useEffect, useState } from "react";
// import { Authcontext } from "../context/Authcontext";
import { Link } from "react-router-dom";
import { Authcontext } from "../component/context/Authcontext";
// import { collection, getDocs, query, where } from "firebase/firestore";
// import { db } from "../../firebase/Config";
const Onlyadmin = ({ children }) => {
  const auth= useContext(Authcontext);
  console.log(auth?.usertype)
    if (auth?.usertype==="admin") {
      return children;
    }else{
      return (
        <section style={{ height: "80vh" }}>
          <div className="container">
            <h2>Permission Denied.</h2>
            <p>This page can only be view by an Admin user.</p>
            <br />
            <Link to="/">
              <button className="--btn">&larr; Back To Home</button>
            </Link>
          </div>
        </section>
      );
    }
};
export const Adminlink = ({ children }) => {
  const auth= useContext(Authcontext);
    if (auth?.usertype==="admin") {
      return children;
    }
    return null
};
export default Onlyadmin;