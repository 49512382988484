import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import i18next, { t } from "i18next";
import Rootrouter from "./pages/Rootrouter";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Gallery from "./pages/Gallery";
import Galleryitem from "./component/gallery/Galleryitem";
import Dashboardroot from "./pages/Dashboardroot";
import Sections from "./component/sections/Sections";
import SectionProjects from "./component/sectionprojects/SectionProjects";
import SectionInputs from "./component/sections/SectionInputs";
import SectionProjectInputs from "./component/sectionprojects/SectionProjectInputs";
import Login from "./component/auth/Login";
import Onlyadmin from "./pages/Onlyadmin";
import Reviews from "./component/reviews/Reviews";
import Reviewsinputs from "./component/reviews/Reviewsinputs";
import PartSection from "./component/gallery/PartSection";
import Partsection from "./component/partsection/Partsection";
import PartsectionInputs from "./component/partsection/PartsectionInputs";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Rootrouter />,
    children: [
      { index: true, element: <Home /> },
      { path: "home", element: <Home /> },
      { path: "aboutus", element: <Aboutus /> },
      { path: "login", element: <Login /> },
    ],
  },
  {
    path: "gallery",
    element: <Rootrouter />,
    children: [
      { index: true, element: <Gallery /> },
      { path: "gallery", element: <Gallery /> },
      { path: "/gallery/galleryitem/:id", element: <Galleryitem /> },
      { path: "/gallery/partsection/:id", element: <PartSection /> },
    ],
  },
  {
    path: "dashboard",
    element: <Dashboardroot />,
    children: [
      { index: true, element: <Onlyadmin><Sections /></Onlyadmin> },
      { path: "sections", element: <Onlyadmin><Sections /></Onlyadmin> },
      { path: "sections/sectioninputs/:id", element: <Onlyadmin><SectionInputs /></Onlyadmin> },
      { path: "projects", element: <Onlyadmin><SectionProjects /></Onlyadmin> },
      { path: "projects/prjectsinputs/:id", element: <Onlyadmin><SectionProjectInputs /></Onlyadmin> },
      { path: "partsection", element: <Onlyadmin><Partsection /></Onlyadmin> },
      { path: "partsection/partsectioninputs/:id", element: <Onlyadmin><PartsectionInputs /></Onlyadmin> },
      { path: "reviews", element: <Onlyadmin><Reviews /></Onlyadmin> },
      { path: "reviews/reviewsinputs/:id", element: <Onlyadmin><Reviewsinputs /></Onlyadmin> },
    ],
  },
]);
function App() {
  const [lang, setLang] = useState(localStorage.lang || "en");
  useEffect(() => {
    localStorage.setItem("lang", lang);
    document.querySelector("html").lang = lang;
    document.documentElement.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr');
    i18next.changeLanguage(lang).then();
  }, [lang]);
  return <RouterProvider router={router} />;
}

export default App;
