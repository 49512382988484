import React from "react";
import { Outlet } from "react-router-dom";
import Navside from "../component/navbar/Navside";
const Dashboardroot=()=>{
    return(
        <div>
            <div className="mx-5 p-5 relative z-10 flex items-center">
                <Navside/>
            </div>
            <div className="z-0 relative min-h-screen">
                <Outlet/>
            </div>
        </div>
    )
}
export default Dashboardroot;