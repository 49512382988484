import React from "react";
import style from "./Footer.module.css"
import {PiFacebookLogoBold} from "react-icons/pi"
import {BsWhatsapp,BsInstagram} from "react-icons/bs"
import {AiOutlineLinkedin} from "react-icons/ai"
import { useTranslation } from "react-i18next";
const Footer=()=>{
    const { t } = useTranslation();
    return(
        <div className={`${style.backimg}  xsm:max-md:px-0  px-24`}>
            <footer className={`xsm:max-md:justify-items-center footer xsm:max-md:p-5 p-10 text-neutral-content text-xl `}>
                <nav className="xsm:max-md:justify-items-center">
                    <header className="footer-title font-bold opacity-100 text-2xl">{t("menu")}</header> 
                    <a href="/" className="link link-hover my-3">{t("home")}</a>
                    <a href="#about" className="link link-hover my-3">{t("about_us")}</a>
                    <a href="#profile" className="link link-hover my-3">{t("portfolio")}</a>
                    {/* <a className="link link-hover my-3">Services</a> */}
                </nav> 
                {/* <nav className="xsm:max-md:justify-items-center">
                    <header className="footer-title font-bold opacity-100 text-2xl">Services</header> 
                    <a className="link link-hover my-3">Interior design</a>
                    <a className="link link-hover my-3">Mechanical Engineering</a>
                    <a className="link link-hover my-3">Electrical Engineering</a>
                    <a className="link link-hover my-3">Civil  Engineering</a>
                </nav>  */}
                {/* <nav className="xsm:max-md:justify-items-center">
                    <header className="footer-title font-bold opacity-100 text-2xl">Information</header> 
                    <a className="link link-hover my-3">Portfolio</a>
                    <a className="link link-hover my-3">Prices</a>
                    <a className="link link-hover my-3">Suppliers</a>
                </nav> */}
                <nav className="xsm:max-md:justify-items-center">
                    <header className="footer-title font-bold opacity-100 text-2xl">{t("contact")}</header> 
                    <a className="link link-hover my-3">{t("phone")} : ⁦+20 102 238 2631</a>
                    <a className="link link-hover my-3">{t("email")} : craivoarch@gmail.com</a>
                </nav>
            </footer>
            {/* <div className="flex justify-center items-center text-[#ffff] ">
                <AiOutlineLinkedin size={45} className="m-3"/>
                <BsInstagram size={45} className="m-3"/>
                <BsWhatsapp size={45} className="m-3"/>
                <PiFacebookLogoBold size={45} className="m-3"/>
            </div> */}
        </div>
    )
}
export default Footer