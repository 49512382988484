import React, { useEffect, useState } from "react";
import Categorycard from "./Categorycard";
import { useNavigate, useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config";
import ProjectCard from "./ProjectCard";
const PartSection=()=>{
    const {id}=useParams();
    const [projects,setProjects]=useState([])
    const navigate= useNavigate();
    const NavigateHandler=(id)=>{
        navigate(`/gallery/galleryitem/${id}`)
    }
    const getProjectsHandler=async()=>{
        const querySnapshot = await getDocs(collection(db, "partSection"));
        const demo=[]
        querySnapshot.forEach((doc) => {
            if(doc.data()?.section===id){
                demo.push(doc.data())
            }
        });
        setProjects(demo)
    }
    useEffect(()=>{
        getProjectsHandler()
    },[])
    return(
        <div className="flex flex-wrap justify-between px-10 pt-40 min-h-screen">
            {projects.map((sec)=>{
                return(
                    <Categorycard onclick={()=>NavigateHandler(sec?.id)} section={sec}/>
                )
            })}
        </div>
    )
}
export default PartSection