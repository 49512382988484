import React from "react";
import bookimg from "../../assets/bookjpg.jpg";
import { HiOutlineMail } from "react-icons/hi";
import { BsPerson, BsTelephone } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
const Booknow = () => {
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();
  return (
    <div className="flex py-16">
      <div
        className={`xsm:max-lg:hidden w-6/12 mt-32 ${
          lang === "ar" ? "rounded-r-md" : "rounded-r-md"
        }`}>
        <img
          className={`${lang === "ar" ? "rounded-l-3xl" : "rounded-r-3xl"}`}
          width="100%"
          src={bookimg}
        />
      </div>
      <div className="w-7/12 xsm:max-lg:w-full flex flex-col items-center justify-center">
        <h2 className="text-5xl font-bold text-center xsm:max-md:font-bold xsm:max-md:text-4xl mb-10">
          {/* Book <span className="text-[#653C1F]">Appointment</span> */}
          {t("Appointment")}
        </h2>
        <p className="text-3xl font-bold text-center xsm:max-md:font-bold xsm:max-md:text-4xl">
          {t("Appointment_des")}
        </p>
        {/* <div className="xsm:max-lg:w-9/12 form-control px-1 py-3  border-0 w-6/12 m-auto bg-[#ffff] rounded-xl items-center justify-evenly flex-row mb-6 my-14">
                    <BsPerson size={35} color="#aaaaaa"/>
                    <input required type="text" placeholder={t("name")} className="input w-10/12 focus:outline-0 placeholder:text-2xl placeholder:font-bold placeholder:text-[#653C1F]" />
                </div>
                <div className="xsm:max-lg:w-9/12 form-control px-1 py-3 border-0 w-6/12 m-auto bg-[#ffff] rounded-xl items-center justify-evenly flex-row mb-6 my-14">
                    <BsTelephone size={35} color="#aaaaaa"/>
                    <input required type="text" placeholder={t("phone")} className="input w-10/12 focus:outline-0 placeholder:text-2xl placeholder:font-bold placeholder:text-[#653C1F]" />
                </div>
                <div className="xsm:max-lg:w-9/12 form-control px-1 py-3 border-0 w-6/12 m-auto bg-[#ffff] rounded-xl items-center justify-evenly flex-row mb-6 my-14">
                    <TfiEmail size={35} color="#aaaaaa"/>
                    <input required type="text" placeholder={t("phone")} className="input w-10/12 focus:outline-0 placeholder:text-2xl placeholder:font-bold placeholder:text-[#653C1F]" />
                </div>
                <div className="xsm:max-lg:w-10/12 form-control px-1 py-3 border-0 w-8/12 m-auto bg-[#ffff] rounded-xl items-center justify-evenly flex-row mb-6 my-14">
                    <textarea required type="text" placeholder={t("message")} className="textarea textarea-lg h-96 w-full focus:outline-0 placeholder:text-2xl placeholder:font-bold placeholder:text-[#653C1F33]" />
                </div>
                <div className="text-center mt-28">
                    <button className="btn bg-[#3B2800] hover:bg-[#3B2800] text-[#ffff] px-28 h-14 text-2xl font-bold">{t("send")}</button>
                </div> */}
      </div>
    </div>
  );
};
export default Booknow;
