import React from "react";
import Header from "../component/gallery/Header";
import Category from "../component/gallery/Category";
const Gallery=()=>{
    return(
        <div>
            <Header/>
            <Category/>
        </div>
    )
}
export default Gallery