import React from "react";
import portfolio1 from "../../assets/portfolio1.png";
import portfolio2 from "../../assets/portfoilo2.png";
import portfolio3 from "../../assets/portfolio3.png";
import button from "../../assets/button.png";
import style from "./Portfolio.module.css";
import { useTranslation } from "react-i18next";
const Portfolio = () => {
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();
  return (
    <div id="profile" className="xsm:max-lg:block w-full flex justify-center p-5 items-center">
      <div
        style={{ textAlign: "-webkit-center" }}
        className={`xsm:max-lg:block xsm:max-lg:w-full xsm:max-lg:right-0 xsm:max-lg:left-0 xsm:max-lg: flex justify-center w-11/12 relative ${
          lang === "ar" ? "right-[-10%]" : "left-[-1%]"
        } xsm:max-lg:left-0`}>
        <h2
          className={` xsm:max-lg:text-7xl xsm:max-lg:text-[black] xsm:max-lg:mb-10 lg:absolute top-[40px] ${
            lang === "ar" ? "left-[-15%]" : "right-[-15%]"
          } text-8xl text-[#ffff] ${style.after}`}>
          {/* Port<span className="text-[#653C1F]">folio</span> */}
          {t("portfolio")}
        </h2>
        <div className="sm:max-md:w-7/12 flex flex-col w-4/12 xsm:max-lg:w-10/12 xsm:max-lg:mb-8 md:max-lg:w-6/12 p-2 text-center items-center">
          <img src={portfolio2} className="w-full" />
          <h2 className="text-3xl mb-5 mt-5 font-bold">
            {/* rio St */}
            {t("portfolio1_head")}
          </h2>
          <p className="text-xl xsm:max-lg:text-lg xsm:max-lg:w-full leading-loose text-[#000000] w-11/12">
            {t("portfolio1_des")}
            {/* In molestiae ut. Qui soluta iure quia ut alias omnis ea quia et. Repudiandae placeat nihil est sa reiciendis eligendi veniam sunt nesciunt saepe. Quo ut autem et sit omnis. Amet et mollitia veritatis quaerat impedit */}
          </p>
        </div>
        <div className="sm:max-md:w-7/12 flex flex-col w-4/12 xsm:max-lg:w-10/12 xsm:max-lg:mb-8 md:max-lg:w-6/12 p-2 text-center items-center">
          <img src={portfolio3} className="w-full" />
          <h2 className="text-3xl xsm:max-lg:text-xl mb-5 mt-5 font-bold">
            {/* Room in Tanta St */}
            {t("portfolio2_head")}
          </h2>
          <p className="text-xl xsm:max-lg:text-lg xsm:max-lg:w-full leading-loose text-[#000000] w-11/12">
            {t("portfolio2_des")}
            {/* In molestiae ut. Qui soluta iure quia ut alias omnis ea quia et. Repudiandae placeat nihil est sa reiciendis eligendi veniam sunt nesciunt saepe. Quo ut autem et sit omnis. Amet et mollitia veritatis quaerat impedit */}
          </p>
        </div>
        <div className="sm:max-md:w-7/12 flex flex-col w-4/12 xsm:max-lg:w-10/12 xsm:max-lg:mb-8 md:max-lg:w-6/12 p-2 text-center items-center">
          <img src={portfolio1} className="w-full" />
          <h2 className="text-3xl mb-5 mt-5 font-bold">
            {/* Office in Zamalek */}
            {t("portfolio3_head")}
          </h2>
          <p className="text-xl xsm:max-lg:text-lg xsm:max-lg:w-full leading-loose text-[#000000] w-11/12">
            {t("portfolio3_des")}
            {/* In molestiae ut. Qui soluta iure quia ut alias omnis ea quia et. Repudiandae placeat nihil est sa reiciendis eligendi veniam sunt nesciunt saepe. Quo ut autem et sit omnis. Amet et mollitia veritatis quaerat impedit */}
          </p>
        </div>
      </div>
      <div className="w-6/12 xsm:max-lg:w-full xsm:max-lg:text-center xsm:max-lg:p-5">
        <p className="text-4xl !font-black xsm:max-md:text-2xl leading-loose text-center">
          {t("portfolio_description")}
          {/* Look at our work . we try to maintain a balance
                    of aesthesis and convenience in our layouts.
                    lightless and softness accompany each of our projects */}
        </p>
        {/* <div className="flex items-center justify-center  mt-16">
          <img className="cursor-pointer" src={button} alt="button" />
          <p className="text-xl font-bold">{t("learn_more")}</p>
        </div> */}
      </div>
    </div>
  );
};
export default Portfolio;
