import React from "react";
import Landing from "../component/home/Landing";
import Aboutus from "../component/home/Aboutus";
import Portfolio from "../component/home/Portfolio";
import Whycravio from "../component/home/Whycravio";
import Ourclients from "../component/home/Ourclients";
import Booknow from "../component/home/Booknow";
import Footer from "../component/footer/Footer";
const Home =()=>{
    return(
        <>
            <Landing/>
            <Aboutus/>
            <Portfolio/>
            <Whycravio/>
            <Ourclients/>
            <Booknow/>
        </>
    )
}
export default Home