import React, { useEffect, useState } from "react";
import { AiFillFolderAdd } from "react-icons/ai";
import { FaRegFolderOpen } from "react-icons/fa";
import { LuClipboardList } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { doc, getDoc , setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase/Config";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
const SectionInputs=()=>{
    const [projPhoto, setProjPhoto] = useState('');
    const navigate = useNavigate();
    const {id}=useParams();
    const [sectionEdite,setSectionEdite]=useState([])
    const formkind=(id,f1,f2)=>{
        if(id==="Add"){
            return f1; 
        }
        return f2;
    } 
    const initialSection={
        name:"",
        photo:"",
    }
    const [currentSection,setCurrentSection]=useState(()=>{
        const newstate=formkind(id,{...initialSection},sectionEdite)
        return newstate
    })
    const inputChange=(e)=>{
        const { name, value } = e.target;
        setCurrentSection({...currentSection,[name]:value});
    }
    const sectionEditeHandler=async()=>{
        const docRef = doc(db, "sections", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setSectionEdite(docSnap.data())
            setCurrentSection(docSnap.data())
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
    }
    useEffect(() => {
        if (id !== "Add") {
            sectionEditeHandler();
        }
    }, [id]);
    const addSectionHandler= async(e)=>{
        e.preventDefault();
        const storageRef = ref(storage, uuid());
        const uploadTask = uploadBytesResumable(storageRef, e.target[1].files[0]);
        uploadTask.on(
            'state_changed', 
            (snapshot) => {
                switch (snapshot.state) {
                case 'paused':
                    break;
                case 'running':
                    break;
                }
            }, 
            (error) => {
                console.log(error)
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then( async (downloadURL) => {
                    const uid= uuid();
                    await setDoc(doc(db, "sections", uid), {
                        name: e.target[0].value,
                        photo:downloadURL,
                        id:uid,
                    });
                });
            }
        );
        navigate("/dashboard/sections")
    }
    const editeSectionHandler=(e)=>{
        e.preventDefault();
        if(e.target[1].files[0]!==undefined){

            const storage = getStorage();
            
            // Create a reference to the file to delete
            const desertRef = ref(storage, sectionEdite?.photo);
            
            // Delete the file
            deleteObject(desertRef).then(() => {
                const storageRef = ref(storage, uuid());
                const file=e.target[1].files[0]
                const uploadTask = uploadBytesResumable(storageRef, e.target[1].files[0]);
                uploadTask.on(
                    'state_changed', 
                    (snapshot) => {
                        switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        }
                    }, 
                    (error) => {
                        console.log(error)
                    }, 
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then( async (downloadURL) => {
                            console.log(downloadURL)
                            const washingtonRef = doc(db, "sections", id);
                            await updateDoc(washingtonRef, {
                                name: e.target[0].value,
                                photo:downloadURL,
                            });
                        });
                    }
                );
            // File deleted successfully
            }).catch((error) => {
            // Uh-oh, an error occurred!
            });
        }else{
            const update= async()=>{
                const washingtonRef = doc(db, "sections", id);
                await updateDoc(washingtonRef, {
                    name: e.target[0].value,
                });
            }
            update();
        }
        navigate("/dashboard/sections")
    }
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setProjPhoto(e.target.files[0].name);
        }
        inputChange(e);
    };
    return(
        <>
            <div className="text-center">
                <h2 className="text-6xl font-black text-[#653C1F]">{formkind(id,"Adding New Section","Edite The Section")}</h2>
            </div>
            <div> 
                <form  onSubmit={formkind(id,addSectionHandler,editeSectionHandler)} className="w-3/12 lg:max-xl:w-4/12 md:max-lg:w-6/12 xsm:max-md:w-9/12  m-auto mt-16">
                    <div className="bg-[#653C1F1A]  form-control p-1 border border-[#653C1F7A] rounded-xl items-center justify-evenly flex-row mb-6">
                        <LuClipboardList size={25} color="#653C1F"/>
                        {/* onChange={(e)=>inputChange(e)} value={curentClient?.companyname} */}
                        <input  name="name" value={currentSection?.name} onChange={(e)=>inputChange(e)} required type="text" placeholder="Section Name" className=" bg-[transparent] text-2xl input w-10/12 focus:outline-0 placeholder:text-[#653C1F] placeholder:text-2xl" />
                    </div>
                    <div className="bg-[#653C1F1A]  form-control p-4 border border-[#653C1F7A] rounded-xl items-center justify-between flex-row mb-6">
                        <div className="flex justify-evenly">
                            <FaRegFolderOpen size={25} color="#653C1F" className="mx-2"/>
                            <label htmlFor="file"  className="text-[#653C1F] text-2xl mx-5 cursor-pointer">{projPhoto ? projPhoto: "Section Photo"}</label>
                        </div>
                        <div className="input input-bordered input-info w-full max-w-xs  hidden">
                            <input
                                onChange={handleFileChange}
                                type="file"
                                name="photo"
                                className="hidden"
                                id="file"
                            />
                        </div>
                        {/* onChange={(e)=>inputChange(e)} value={curentClient?.companyname} */}
                        <label htmlFor="file" className=" cursor-pointer" style={{textAlign:"-webkit-right"}}>
                                <AiFillFolderAdd color="#653C1F" size={40}/>
                        </label>
                    </div>
                    <button type="submit" className="w-full mt-8 btn bg-[#653C1F] border-0 text-2xl h-14 px-10 text-[white] hover:border-2 hover:bg-[transparent] hover:border-[#653C1F] hover:text-[#653C1F] md:max-lg:px-7 xsm:max-md:px-1 xsm:max-md:text-sm ">{formkind(id,"Adding New Section","Edite The Section")}</button>
                    <br></br>
                    <button onClick={()=>navigate("/dashboard/sections")} className="w-full mt-4 mb-10 btn btn-outline border-2 border-[#653C1F] px-10 text-2xl h-14 text-[#653C1F] hover:border-0 hover:bg-[#653C1F] md:max-lg:px-7 xsm:max-md:px-1 xsm:max-md:text-sm "> Cancel</button>
                </form>
            </div>
        </>
    )
}
export default SectionInputs