import React, { useEffect, useState } from "react";
import leftLine from "../../assets/leftLine.png"
import rightLine from "../../assets/rightLine.png"
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { FaSlidersH } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config";
const Reviews=()=>{
    const navigate = useNavigate();
    const [projects,setProjects]=useState([])
    const getProjectsHandler=async()=>{
        const querySnapshot = await getDocs(collection(db, "reviews"));
        const demo=[]
        querySnapshot.forEach((doc) => {
            console.log(doc?.data())
            demo.push(doc.data())
        });
        setProjects(demo)
    }
    useEffect(()=>{
        getProjectsHandler()
    },[])
    const deleteProject=async (id)=>{
        await deleteDoc(doc(db, "reviews", id));
        getProjectsHandler()
    }
    return(
        <div className="min-h-screen relative">
            <div className="text-right  px-5">
                <button onClick={()=>navigate("/dashboard/reviews/reviewsinputs/Add")} className="btn bg-[#653C1F] text-[#ffff] hover:bg-[#885633]">Add Project</button>
            </div>
            <div className="divider mb-5"></div> 
            {/* <div className="pl-32">
                <FaSlidersH size={35} cursor="pointer" color="#343330" />
            </div> */}
            <div className="flex justify-center relative">

                <div className="overflow-x-auto z-10 my-14 shadow-lg w-6/12 rounded-md ">
                    <table className="table">
                        {/* head */}
                        <thead>
                        <tr className=" text-[#653C1F] font-black text-2xl text-center">
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Comment</th>
                            <th colspan="2">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="text-[#00000080] text-2xl font-black text-center">
                            {
                                projects?.map((proj)=>{
                                    return(
                                        <tr>
                                            <td className="border-x border-[white]">
                                                <div tabIndex={0} role="button" className=" w-24 h-auto btn btn-ghost btn-circle avatar">
                                                    <div className=" rounded-full">
                                                        <img alt="Tailwind CSS Navbar component" src={proj?.photo} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="border-x border-[white]">{proj?.name}</td>
                                            <td>
                                                <p>{proj?.comment}</p>
                                            </td>
                                            <td className="border-x border-[white]">
                                                <GrEdit onClick={()=>navigate(`/dashboard/reviews/reviewsinputs/${proj?.id}`)} className="m-auto  cursor-pointer" />
                                            </td>
                                            <td className="border-x border-[white]">
                                                <MdDelete onClick={()=>deleteProject(proj?.id)} className="m-auto text-[#C10F0F] cursor-pointer" />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        {/* row 1 */}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="absolute bottom-0 right-0">
                <img src={rightLine}/>
            </div>
        </div>
    )
}
export default Reviews
