import React from "react";
import background from "../../assets/landingimg.jpg";
import { BsInstagram } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";
import { FaDribbble } from "react-icons/fa";
import style from "./Landing.module.css";
import { useTranslation } from "react-i18next";
const Landing = () => {
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();
  return (
    <div className=" min-h-screen relative xsm:max-lg:pb-5 xsm:max-lg:px-5">
      <div
        className={`w-9/12 xsm:max-lg:w-full xsm:max-lg:rounded-none h-full absolute ${
          lang === "ar" ? "left-0" : "right-0"
        } overflow-hidden ${
          lang === "ar" ? "rounded-r-3xl" : "rounded-l-3xl"
        } z-0`}>
        <div
          className={`absolute w-full h-full left-0 right-0 bg-[#ffffff] ${style?.animation}`}></div>
        <img
          className={`h-full w-full  ${style.zoom}`}
          src={background}
          alt="background"
        />
      </div>
      <div className="pt-48 mb-16 px-44 text-8xl font-light z-10 relative text-[#ffff]  md:max-xl:px-32 md:max-lg:px-10 md:max-lg:text-5xl xsm:max-md:text-5xl xsm:max-md:px-5">
        <h2 className="w-9/12 md:max-xl:w-full md:max-lg:w-full md:max-lg:text-center xsm:max-md:text-center xsm:max-md:w-full">
          {t("home_welcom")}

          {/* <span className="text-[#000000] md:max-lg:text-[#ffff] xsm:max-md:text-[#ffff]">
            Welcom
          </span>
          e To Our{" "}
          <span className="text-[#000000] md:max-lg:text-[#ffff] xsm:max-md:text-[#ffff]">
            interior
          </span>{" "}
          <span className={style.afterdesign}>Design</span>{" "}
          <span
            className={`text-[#000000] md:max-lg:text-[#ffff] xsm:max-md:text-[#ffff] ${style.aftercompany}`}>
            Compa
          </span>
          ny */}
        </h2>
        <h2 className="w-9/12 ml-28 mr-28 xsm:max-lg:m-0 text-5xl md:max-xl:w-full md:max-lg:w-full md:max-lg:text-center xsm:max-md:text-center xsm:max-md:w-full">
          {t("home_welcom2")}

          {/* <span className="text-[#000000] md:max-lg:text-[#ffff] xsm:max-md:text-[#ffff]">
            Welcom
          </span>
          e To Our{" "}
          <span className="text-[#000000] md:max-lg:text-[#ffff] xsm:max-md:text-[#ffff]">
            interior
          </span>{" "}
          <span className={style.afterdesign}>Design</span>{" "}
          <span
            className={`text-[#000000] md:max-lg:text-[#ffff] xsm:max-md:text-[#ffff] ${style.aftercompany}`}>
            Compa
          </span>
          ny */}
        </h2>
      </div>
      <div className="flex justify-between z-10 relative items-center leading-9 xsm:max-md:inline">
        <div className="p-10 w-3/12 xsm:max-md:w-10/12 xsm:max-lg:text-[#ffff] xsm:max-md:m-auto xsm:max-md:text-center">
          {/* <p>{t("home_ability")}</p> */}
          {/* <button className="btn bg-[#3B2800] text-[#ffff] mt-8 px-8">
            {t("learn_more")}
          </button> */}
          {/* <div className="flex xsm:max-md:justify-center">
            <BsInstagram size={30} className="mt-10 mx-5" />
            <FiTwitter size={30} className="mt-10 mx-5" />
            <FaDribbble size={30} className="mt-10 mx-5" />
          </div> */}
        </div>
        <div style={{ minWidth: "75%" }}>
          <hr className="mb-16 border-2 w-8/12 m-auto text-[#ffff] xsm:max-lg:w-11/12"></hr>
          <div className="flex items-center justify-between bg-[#FFFFFF3B] text-[#ffff] p-6 rounded-xl w-10/12 m-auto  h-fit xsm:max-md:w-11/12 xsm:max-lg:inline xsm:max-lg:bg-[#FFFFFF3B]  xsm:max-lg:text-center">
            <div className="text-center xsm:max-lg:mb-5 xsm:max-lg:bg-[#FFFFFF3B] xsm:max-lg:p-5 xsm:max-lg:rounded-lg xsm:max-lg:text-lg">
              <p className="font-bold  text-3xl">{t("home_ability")}</p>
              <h2 className="">{t("ability")}</h2>
            </div>
            <div className="text-center xsm:max-lg:mb-5 xsm:max-lg:bg-[#FFFFFF3B] xsm:max-lg:p-5 xsm:max-lg:rounded-lg xsm:max-lg:text-lg">
              <h2 className="font-bold text-3xl">{t("quality")}</h2>
              <p>{t("quality_describtion")}</p>
            </div>
            <div className="text-center xsm:max-lg:mb-5 xsm:max-lg:bg-[#FFFFFF3B] xsm:max-lg:p-5 xsm:max-lg:rounded-lg xsm:max-lg:text-lg">
              <h2 className="font-bold text-3xl">{t("beauty")}</h2>
              <p>{t("beauty_describtion")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Landing;
