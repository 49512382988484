import React, { useEffect, useState } from "react";
import Categorycard from "./Categorycard";
import {useNavigate} from "react-router-dom"
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config";
const Category=()=>{
    const navigate= useNavigate();
    const [sections,setSections]=useState([])
    const NavigateHandler=(id)=>{
        navigate(`/gallery/partsection/${id}`)
    }
    const getSectionsHandler=async()=>{
        const querySnapshot = await getDocs(collection(db, "sections"));
        const demo=[]
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            demo.push(doc.data())
        });
        setSections(demo)
    }
    useEffect(()=>{
        getSectionsHandler()
    },[])
    return(
        <div className="flex flex-wrap justify-between p-10 min-h-screen">
            {sections?.map((sec)=>{
                return(
                    <Categorycard onclick={()=>NavigateHandler(sec?.id)} section={sec}/>
                )
            })}
        </div>
    )
}
export default Category