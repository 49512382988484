import React, { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../../firebase/Config";
export const Authcontext = createContext();
export const Authcontextprovider = (props) => {
  const [currentuser, setCurrentuser] = useState(null);
  const [usertype, setUsertype] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
        console.log(user)
      if (user) {
        try {
          const q = query(
            collection(db, "users"),
            where("uid", "==", user.uid)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            setUsertype(doc.data().rule);
            setCurrentuser(doc.data());
          });
        } catch (error) {}
      }else{
        setUsertype(null);
        setCurrentuser(null);
      }
    });
  }, []);
  return (
    <Authcontext.Provider value={{ currentuser, usertype }}>
      {props.children}
    </Authcontext.Provider>
  );
};
