import React from "react";
import leftarrow from "../../assets/leftarrow.png";
import rightarrow from "../../assets/rightarrow.png";
import icon from "../../assets/whyicon.png";
import style from "./Whychooseus.module.css";
import { BsTelephone } from "react-icons/bs";
import { useTranslation } from "react-i18next";
const Whychooseus = () => {
  const { t } = useTranslation();
  return (
    <div className="py-10 ">
      {/* <div className=" xsm:max-lg:block xsm:max-lg:text-center pt-40  xsm:max-md:pt-14 mb-10 flex justify-between items-center xsm:max-md:w-full xsm:max-md:px-5 w-8/12 m-auto">
        <img src={leftarrow} className="lg:block hidden" />
        <h2 className="text-8xl xsm:max-lg:text-5xl xsm:max-lg:font-bold">
          Who We <span className="text-[#653C1F]">Are</span>
        </h2>
        <img src={rightarrow} className="lg:block hidden" />
      </div> */}
      <div className={`text-center p-10 rounded-t-3xl ${style.background}`}>
      <ul className="mt-24">
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why1")}
              {/* Develop a layout tat meets the requirements of the Snip and
              coordinate it */}
            </p>
          </li>
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why2")}
              {/* Save your nerves and time when communicating with Contractors or
              choosing martials */}
            </p>
          </li>
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why3")}
              {/* Prevent unnecessary costs associated with alteration */}
            </p>
          </li>
          <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center  text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p className="font-black text-5xl">
              {t("why4")}
              {/* Generate an accurate estimate for furniture,matrials,and
              construction work{" "} */}
            </p>
          </li>
          {/* <li className="xsm:max-md:w-11/12 xsm:max-md:text-xl flex items-center text-[#ffff] text-2xl w-6/12 m-auto text-left mt-14">
            <div className="h-[4px] w-[27px] bg-[#ffff] mx-5"></div>
            <p>
              {t("why5")}
            </p>
          </li> */}
        </ul>
        <div className="xsm:max-md:block flex justify-center items-center text-[#ffff] mt-16 text-left">
          <div className="xsm:max-md:w-full xsm:max-md:text-center flex flex-col w-64 text-center justify-center p-10">
            <h2 className="text-5xl">53</h2>
            <p className="text-2xl">
              {/* Experienced designers */}
              {t("special_need")}
            </p>
          </div>
          <div className="xsm:max-md:w-full xsm:max-md:text-center flex flex-col w-64 text-center justify-center p-10">
            <h2 className="text-5xl">77</h2>
            <p className="text-2xl">
              {/* Ready-modeProjects */}
              {t("ready_mode")}
            </p>
          </div>
          <div className="xsm:max-md:w-full xsm:max-md:text-center flex flex-col w-64 text-center justify-center p-10">
            <h2 className="text-5xl">80</h2>
            <p className="text-2xl">
              {/* Clients Reviews */}
              {t("client_grade")}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-between items-center w-7/12 m-auto bg-[#303E36BF] text-[#ffff] p-5 rounded-2xl xsm:max-md:block xsm:max-md:w-10/12">
        <div className="xsm:max-md:block xsm:max-md:text-center flex items-center justify-between w-4/12 xsm:max-md:w-full">
          <BsTelephone
            size={50}
            className="xsm:max-md:m-auto xsm:max-md:mb-5"
          />
          <div>
            <p className="text-lg">Get A free Conversation</p>
            <p className="text-xl">0123455678990</p>
          </div>
        </div>
        <div className="w-2/12 xsm:max-md:w-full xsm:max-md:h-[100px] xsm:max-md:block xsm:max-md:rotate-90 xsm:max-md:relative">
          <svg
            className="m-auto xsm:max-md:absolute xsm:max-md:top-2/4 xsm:max-md:left-2/4 xsm:max-md:-translate-x-2/4 xsm:max-md:-translate-y-2/4"
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="24"
            viewBox="0 0 90 24"
            fill="none">
            <rect
              width="86"
              height="2"
              transform="translate(0 11)"
              fill="white"
            />
            <path
              d="M88.6935 13.1113L75.4605 23.546C75.0876 23.84 74.5818 24.0052 74.0545 24.0052C73.5271 24.0052 73.0214 23.84 72.6485 23.546C72.2756 23.2519 72.0661 22.8531 72.0661 22.4373C72.0661 22.0215 72.2756 21.6226 72.6485 21.3286L84.4771 12.0039L72.6518 2.6766C72.4672 2.53101 72.3207 2.35816 72.2208 2.16793C72.1208 1.97771 72.0694 1.77382 72.0694 1.56792C72.0694 1.36202 72.1208 1.15813 72.2208 0.967901C72.3207 0.777673 72.4672 0.604827 72.6518 0.459233C72.8364 0.313638 73.0556 0.198146 73.2969 0.119351C73.5381 0.0405556 73.7967 -1.53409e-09 74.0578 0C74.3189 1.53409e-09 74.5775 0.0405556 74.8187 0.119351C75.06 0.198146 75.2792 0.313638 75.4638 0.459233L88.6968 10.8939C88.8816 11.0395 89.0282 11.2124 89.1281 11.4028C89.228 11.5931 89.2793 11.7971 89.279 12.0031C89.2786 12.2091 89.2268 12.4131 89.1263 12.6032C89.0258 12.7934 88.8787 12.966 88.6935 13.1113Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="w-6/12 xsm:max-md:w-full xsm:max-md:my-5 xsm:max-md:text-center">
          <p>
            Get all the information you need in the field of interior design and
            also learn about the prices of designs through your conversation
            with one of the specialists.
          </p>
        </div>
        <div className="w-4/12 xsm:max-md:w-full text-center">
          <button className="btn bg-[#ffff] rounded-3xl">
            Make Appointment
          </button>
        </div>
      </div> */}
    </div>
  );
};
export default Whychooseus;
